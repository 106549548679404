import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {trackEcoFriendlyHoverEvent} from 'utils/metrics/listingsMetrics';
import EcoFriendlyIcon from './EcoFriendlyIcon';
import FlagUSIcon from './FlagUSIcon';
import ProductBadge from './ProductBadge';

// Badge icon mapping
const BADGE_ICONS = {
  eco: EcoFriendlyIcon,
  usa: FlagUSIcon
};

// Badge config for tracking and titles
const BADGE_CONFIG = {
  'Eco-friendly': {
    onMouseEnter: (productName) => trackEcoFriendlyHoverEvent(productName),
    title: 'This is an eco-friendly product!'
  },
  'Made in USA': {
    title: 'This is a made in USA product!'
  }
};

export default function ProductFeatureBadges({
  badges,
  compact = false,
  productName
}) {
  if (!badges) return null;

  // Get feature badges (type: 'feature')
  const featureBadges = useMemo(() => {
    return badges.filter((badge) => badge.type === 'feature');
  }, [badges]);

  // If no feature badges, return null
  if (!featureBadges.length) return null;

  const badge = featureBadges[0];
  const variant = badge.params?.variant;
  const {label} = badge;
  const config = BADGE_CONFIG[label] || {};

  return (
    <ProductBadge
      compact={compact}
      icon={BADGE_ICONS[variant]}
      label={label}
      title={config.title}
      variant={variant}
      onMouseEnter={
        config.onMouseEnter ? () => config.onMouseEnter(productName) : undefined
      }
    />
  );
}

ProductFeatureBadges.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      params: PropTypes.object,
      type: PropTypes.string.isRequired
    })
  ),
  compact: PropTypes.bool,
  productName: PropTypes.string
};
