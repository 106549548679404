import PropTypes from 'prop-types';
import React from 'react';

export default function EcoFriendlyIcon({
  className = '',
  height = 24,
  style = {verticalAlign: 'middle'},
  width = 24
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={width}
      height={height}
      className={className}
      style={style}>
      <circle cx="50%" cy="50%" r="50%" fill="#B6EDAF" />
      <path
        fill="rgba(0,0,0,0.79)"
        d="M25.645 6.535s-.068-.03-.622-.165c-12.75-3.218-17.7 2.873-17.7 8.49 0 2.047.667 4.035 2.07 5.407-.136.383-.263.773-.376 1.163-.877-1.388-2.16-2.415-3.87-2.933a1.127 1.127 0 00-1.402.75c-.18.593.157 1.223.75 1.403 1.402.427 2.355 1.328 2.97 2.618.307.652.51 1.357.622 2.07a6.545 6.545 0 01.083.952 1.117 1.117 0 001.372 1.102c.503-.105.878-.547.893-1.08 0-.09.015-.285.037-.555.038-.472.105-.997.21-1.567a19.21 19.21 0 01.653-2.647c.862.36 1.867.562 3.022.562 3.72 0 6.413-2.093 7.298-5.85.735-3.105 1.132-4.837 3.382-7.545.473-.57.923-1.035.923-1.035.42-.465.277-.967-.315-1.14zm-4.643 4.245c-.495 1.05-.832 2.085-1.207 3.578-.09.36-.15.607-.33 1.372-.637 2.715-2.407 4.118-5.115 4.118-.833 0-1.56-.143-2.183-.428 1.043-2.175 2.506-3.907 4.448-5.07a1.117 1.117 0 00.383-1.537 1.117 1.117 0 00-1.538-.383c-2.197 1.32-3.87 3.218-5.077 5.543-.54-.818-.825-1.875-.825-3.113 0-2.31 1.05-4.387 3.037-5.67 2.535-1.635 6.503-1.807 10.013-1.057a15.994 15.994 0 00-1.605 2.647z"
      />
    </svg>
  );
}

EcoFriendlyIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
