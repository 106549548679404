import {
  ALWAYS_NEW_BADGE_PRODUCT_IDS,
  BADGING_NAME_MAP
} from './algolia_constants';

export function hasNewBadge({badges, hit}) {
  return (
    (badges.new_badge_style_ids?.includes(Number(hit.product_id)) &&
      !badges.excluded_new_badge_ids?.includes(Number(hit.product_id))) ||
    ALWAYS_NEW_BADGE_PRODUCT_IDS.includes(Number(hit.product_id))
  );
}

export function hasRecommendationBadge({
  activeCategoryId,
  badgeName,
  badges,
  productId
}) {
  if (badges == null) {
    return false;
  }

  const productIdString = String(productId);

  if (
    Object.keys(badges[BADGING_NAME_MAP[badgeName]]).includes(productIdString)
  ) {
    const hitBadgeArray = badges[BADGING_NAME_MAP[badgeName]][productIdString];
    return hitBadgeArray.includes(Number(activeCategoryId));
  }

  return false;
}
