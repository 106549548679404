export const ALWAYS_NEW_BADGE_PRODUCT_IDS = [1153000];
export const IMAGE_PIXEL_DENSITY_DPRS = {'1x': 1, '2x': 1.6};
export const ALGOLIA_PERMITTED_NAV_PARAMS = [
  'iframer',
  'compatible_style',
  'compatible_type',
  'cid',
  'digest',
  'multi_item',
  'preferred_colors',
  'keep_shopping',
  'quote_fc',
  'quote_bc',
  'min_qty[]',
  'color_limit',
  'rush_delivery_turntimes'
];
export const SEARCH_NAV_PARAMS_TO_PERSIST = [
  'utf8',
  'searchType',
  'iframer',
  'compatible_style',
  'compatible_type',
  'cid',
  'digest',
  'multi_item',
  'preferred_colors',
  'quote_fc',
  'quote_bc',
  'min_qty[]',
  'color_limit'
];
export const ALGOLIA_COLOR_NAME_FILTERS = [
  'min_qty_colors.simple_names',
  'colors.simple_names'
];
export const MENU_KEYS = ['specialty'];
export const NUMERIC_MENU_KEYS = ['min_order_qty', 'rush_delivery_turntimes'];
export const MIN_QTY_COLORS_FILTER_NAME = 'min_qty_colors.simple_names';
export const INTERNAL_FILTERS = [
  'halftones',
  'international_ship_eligible',
  'brand',
  'broad_category_names',
  'sub_category_names',
  'price_level',
  'desired_quantity',
  'search_data_material',
  'min_qty',
  'estimated_qty',
  'no_minimum',
  'rush_turn_time',
  'sizes',
  'decoration_method',
  'turn_time',
  'weight'
];
export const INTERNAL_QC_FORM_INPUT_PARAMS = [
  'estimated_qty',
  'quote_fc',
  'quote_bc'
];

export const INVISIBLE_FILTERS = ['compatible_type', 'sort_by'];

export const AUTOCOMPLETE_FACETS = [
  {name: 'categories.name', caption: 'Categories', showNum: 8},
  {name: 'manufacturer', caption: 'Brands', showNum: 8},
  {name: 'colors.name', caption: 'Colors', showNum: 8}
];

export const AUTOCOMPLETE_HITS_TO_SHOW = 6;

export const BADGING_NAME_MAP = {
  bestSeller: 'best_seller_badge_ids',
  customerFavorite: 'customer_favorite_badge_ids',
  staffPick: 'staff_pick_badge_ids'
};
