import PropTypes from 'prop-types';
import React from 'react';

export default function FlagUSIcon({
  className = '',
  height = 24,
  style = {verticalAlign: 'middle'},
  width = 24
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 42 42"
      width={width}
      height={height}
      className={className}
      style={style}>
      <defs>
        <path
          d="M.18 19.481c0 10.7 8.674 19.374 19.374 19.374 10.699 0 19.372-8.674 19.372-19.374S30.253.108 19.554.108C8.854.108.18 8.782.18 19.481z"
          id="b"
        />
        <path
          d="M21.486 3.337A19.282 19.282 0 0010.774.108C6.814.108 3.13 1.297.062 3.337h21.424z"
          id="d"
        />
        <path
          d="M33.102 3.431a19.457 19.457 0 00-2.67-3.395H2.822a19.456 19.456 0 00-2.67 3.395h32.95z"
          id="f"
        />
        <path
          d="M37.873 3.525A19.226 19.226 0 0036.774.13H1.162a19.226 19.226 0 00-1.1 3.395h37.811z"
          id="h"
        />
        <path
          d="M.254 3.424a19.625 19.625 0 010-3.395h38.599a19.626 19.626 0 010 3.395H.253z"
          id="j"
        />
        <path
          d="M.063.124c.263 1.175.633 2.31 1.099 3.395h35.612c.466-1.084.836-2.22 1.1-3.395H.062z"
          id="l"
        />
        <path
          d="M.152.023a19.457 19.457 0 002.67 3.395h27.61a19.457 19.457 0 002.67-3.395H.152z"
          id="n"
        />
        <path
          d="M.062.117a19.283 19.283 0 0010.712 3.229c3.96 0 7.643-1.189 10.712-3.229H.062z"
          id="p"
        />
        <path
          d="M.254 21.179A19.625 19.625 0 01.18 19.48C.18 8.867 8.716.247 19.298.11v21.069H.254z"
          id="r"
        />
        <path d="M.958.15a19.23 19.23 0 00-.78 2.171h1.2V.151h-.42z" id="t" />
        <path
          d="M.228.02a19.449 19.449 0 00-.222 2.17h1.958V.02H.228z"
          id="v"
        />
        <filter
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
          filterUnits="objectBoundingBox"
          id="a">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation=".5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        filter="url(#a)"
        transform="translate(1)"
        fill="none"
        fillRule="evenodd">
        <g transform="translate(.195 .058)">
          <mask id="c" fill="#fff">
            <use xlinkHref="#b" />
          </mask>
          <path
            fill="#FFF"
            mask="url(#c)"
            d="M53.837 39.02H-7.999V-.058h61.836z"
          />
        </g>
        <g transform="translate(8.975 .058)">
          <mask id="e" fill="#fff">
            <use xlinkHref="#d" />
          </mask>
          <path
            fill="#C70048"
            mask="url(#e)"
            d="M45.058 3.337H-16.78V-.059h61.837z"
          />
        </g>
        <g transform="translate(3.122 5.911)">
          <mask id="g" fill="#fff">
            <use xlinkHref="#f" />
          </mask>
          <path
            fill="#C70048"
            mask="url(#g)"
            d="M50.91 3.431h-61.836V.036h61.837z"
          />
        </g>
        <g transform="translate(.78 11.764)">
          <mask id="i" fill="#fff">
            <use xlinkHref="#h" />
          </mask>
          <path
            fill="#C70048"
            mask="url(#i)"
            d="M53.252 3.525H-8.584V.13h61.836z"
          />
        </g>
        <g transform="translate(.195 17.813)">
          <mask id="k" fill="#fff">
            <use xlinkHref="#j" />
          </mask>
          <path
            fill="#C70048"
            mask="url(#k)"
            d="M53.837 3.424H-7.999V.029h61.836z"
          />
        </g>
        <g transform="translate(.78 23.666)">
          <mask id="m" fill="#fff">
            <use xlinkHref="#l" />
          </mask>
          <path
            fill="#C70048"
            mask="url(#m)"
            d="M53.252 3.519H-8.584V.123h61.836z"
          />
        </g>
        <g transform="translate(3.122 29.714)">
          <mask id="o" fill="#fff">
            <use xlinkHref="#n" />
          </mask>
          <path
            fill="#C70048"
            mask="url(#o)"
            d="M50.91 3.418h-61.836V.022h61.837z"
          />
        </g>
        <g transform="translate(8.975 35.567)">
          <mask id="q" fill="#fff">
            <use xlinkHref="#p" />
          </mask>
          <path
            fill="#C70048"
            mask="url(#q)"
            d="M45.058 3.512H-16.78V.116h61.837z"
          />
        </g>
        <g transform="translate(.195 .058)">
          <mask id="s" fill="#fff">
            <use xlinkHref="#r" />
          </mask>
          <path
            fill="#06417D"
            mask="url(#s)"
            d="M19.298 21.179H-7.999V-.059h27.297z"
          />
          <path
            fill="#FFF"
            mask="url(#s)"
            d="M12.599.37l.352.715.79.114-.572.557.135.785-.705-.37-.706.37.135-.785-.57-.557.788-.114z"
          />
        </g>
        <path
          fill="#FFF"
          d="M17.427.428l.353.715.789.115-.571.556.134.785-.705-.37-.705.37.135-.785-.57-.556.787-.115zM10.48 2.81l.353.714.788.115-.57.557.134.785-.705-.371-.705.37.134-.784-.57-.557.788-.115zM15.114 2.81l.353.714.788.115-.57.557.134.785-.705-.371-.706.37.135-.784-.57-.557.788-.115zM8.16 4.98l.354.715.788.115-.57.556.134.785-.705-.37-.705.37.134-.785-.57-.556.788-.115zM12.794 4.98l.353.715.788.115-.57.556.134.785-.705-.37-.705.37.134-.785-.57-.556.788-.115zM17.427 4.98l.353.715.789.115-.571.556.134.785-.705-.37-.705.37.135-.785-.57-.556.787-.115zM5.847 7.362l.352.715.789.114-.571.557.135.785-.705-.37-.706.37.135-.785-.571-.557.789-.114zM10.48 7.362l.353.715.788.114-.57.557.134.785-.705-.37-.705.37.134-.785-.57-.557.788-.114zM15.114 7.362l.353.715.788.114-.57.557.134.785-.705-.37-.706.37.135-.785-.57-.557.788-.114zM3.528 9.533l.353.715.788.115-.57.557.134.785-.705-.371-.705.37.134-.784-.57-.557.788-.114zM8.16 9.533l.354.715.788.114-.57.557.134.785-.705-.371-.705.37.134-.784-.57-.557.788-.114zM12.794 9.533l.353.715.788.114-.57.557.134.785-.705-.371-.705.37.134-.784-.57-.557.788-.114zM17.427 9.533l.353.715.789.114-.571.557.134.785-.705-.371-.705.37.135-.784-.57-.557.787-.114z"
        />
        <g transform="translate(.976 11.764)">
          <mask id="u" fill="#fff">
            <use xlinkHref="#t" />
          </mask>
          <path
            fill="#FFF"
            mask="url(#u)"
            d="M.237.15L.59.866l.788.114-.57.557.134.785-.705-.37-.705.37.135-.785-.571-.557.788-.114z"
          />
        </g>
        <path
          fill="#FFF"
          d="M5.847 11.915l.352.714.789.115-.571.556.135.785-.705-.37-.706.37.135-.785-.571-.556.789-.115zM10.48 11.915l.353.714.788.115-.57.556.134.785-.705-.37-.705.37.134-.785-.57-.556.788-.115zM15.114 11.915l.353.714.788.115-.57.556.134.785-.705-.37-.706.37.135-.785-.57-.556.788-.115zM3.528 14.085l.353.715.788.115-.57.556.134.785-.705-.37-.705.37.134-.785-.57-.556.788-.115zM8.16 14.085l.354.715.788.115-.57.556.134.785-.705-.37-.705.37.134-.785-.57-.556.788-.115zM12.794 14.085l.353.715.788.115-.57.556.134.785-.705-.37-.705.37.134-.785-.57-.556.788-.115zM17.427 14.085l.353.715.789.115-.571.556.134.785-.705-.37-.705.37.135-.785-.57-.556.787-.115z"
        />
        <g transform="translate(.39 16.447)">
          <mask id="w" fill="#fff">
            <use xlinkHref="#v" />
          </mask>
          <path
            fill="#FFF"
            mask="url(#w)"
            d="M.823.02l.352.715.789.114-.571.557.135.785-.705-.371-.706.37.135-.784-.57-.557.787-.114z"
          />
        </g>
        <path
          fill="#FFF"
          d="M5.847 16.467l.352.715.789.114-.571.557.135.785-.705-.371-.706.37.135-.784-.571-.557.789-.114zM10.48 16.467l.353.715.788.114-.57.557.134.785-.705-.371-.705.37.134-.784-.57-.557.788-.114zM15.114 16.467l.353.715.788.114-.57.557.134.785-.705-.371-.706.37.135-.784-.57-.557.788-.114zM3.528 18.638l.353.714.788.115-.57.556.134.786-.705-.37-.705.37.134-.786-.57-.556.788-.115zM8.16 18.638l.354.714.788.115-.57.556.134.786-.705-.37-.705.37.134-.786-.57-.556.788-.115zM12.794 18.638l.353.714.788.115-.57.556.134.786-.705-.37-.705.37.134-.786-.57-.556.788-.115zM17.427 18.638l.353.714.789.115-.571.556.134.786-.705-.37-.705.37.135-.786-.57-.556.787-.115z"
        />
      </g>
    </svg>
  );
}

FlagUSIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
