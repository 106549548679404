import {useMemo, useRef} from 'react';
import {hasNewBadge, hasRecommendationBadge} from './utils/algoliaRulesBadges';

const badgeTypes = ['bestSeller', 'customerFavorite', 'staffPick'];
const badgeLabels = {
  bestSeller: 'Best\nSeller',
  customerFavorite: 'Customer\nFave',
  staffPick: 'Staff\nPick'
};

// Pre-convert badge IDs to numbers once to avoid repeated conversions
const getNumberBadgeIds = (badges) => {
  if (!badges) return {};

  return {
    ecoIds: badges.eco_style_badge_ids?.map(Number) || [],
    usaIds: badges.usa_style_badge_ids?.map(Number) || []
  };
};

export default function useAlgoliaRulesProductBadges({
  badges,
  categoryId,
  enabled = true,
  hits
}) {
  // Pre-process badge IDs outside the reduce loop
  const {ecoIds, usaIds} = useMemo(() => getNumberBadgeIds(badges), [badges]);

  // Cache to store previously calculated badges
  const badgesCache = useRef({});

  const productBadges = useMemo(() => {
    if (!hits || !hits.length || !badges || !enabled) return {};

    const result = {...badgesCache.current};

    hits.forEach((hit) => {
      const productId = hit.product_id;

      // Skip if we already calculated badges for this product
      if (result[productId]) return;

      const activeCategoryId = categoryId || hit.primary_category_id;
      const numericProductId = Number(productId);
      const badgeList = [];

      // Helper to add a badge to the result
      const addBadge = (badge) => {
        badgeList.push(badge);
      };

      // Process recommendation badges (Best Seller, Customer Favorite, Staff Pick)
      badgeTypes.forEach((badgeType) => {
        if (
          hasRecommendationBadge({
            activeCategoryId,
            badgeName: badgeType,
            badges,
            productId
          })
        ) {
          addBadge({
            label: badgeLabels[badgeType],
            params: {
              recommendation: true
            },
            type: 'promo'
          });
        }
      });

      // Process New badge
      if (hasNewBadge({badges, hit})) {
        addBadge({
          label: 'New!',
          type: 'promo'
        });
      }

      // Process Eco-friendly badge
      if (ecoIds.includes(numericProductId)) {
        addBadge({
          label: 'Eco-friendly',
          params: {
            variant: 'eco'
          },
          type: 'feature'
        });
      }

      // Process Made in USA badge
      if (usaIds.includes(numericProductId)) {
        addBadge({
          label: 'Made in USA',
          params: {
            variant: 'usa'
          },
          type: 'feature'
        });
      }

      // Only add to result if we found badges
      if (badgeList.length > 0) {
        result[productId] = badgeList;
      }
    });

    // Update the cache with new results
    badgesCache.current = result;

    return result;
  }, [badges, categoryId, hits, ecoIds, usaIds]);

  return productBadges;
}
