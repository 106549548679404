import {Box, Typography} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import PropTypes from 'prop-types';
import React from 'react';

const BadgeContainer = ({
  children,
  compact = false,
  onMouseEnter,
  onMouseLeave,
  title,
  variant = 'default'
}) => {
  // Base styles
  const baseStyles = {
    alignItems: 'center',
    borderRadius: '0.5rem 0 0 0',
    bottom: 0,
    color: '#000000db',
    display: 'flex',
    fontSize: '0.75rem',
    fontWeight: 700,
    gap: '0.125rem',
    lineHeight: '1',
    minHeight: '1.25rem',
    padding: '2px 6px 2px 4px',
    position: 'absolute',
    right: 0
  };

  // Compact mode styles
  const compactStyles = {
    backgroundColor: 'unset',
    borderRadius: '999px',
    bottom: '0.5rem',
    fontSize: '12px',
    fontWeight: 700,
    justifyContent: 'center',
    lineHeight: 1,
    padding: 0,
    right: '0.5rem'
  };

  // Variant specific styles
  const variantStyles = {
    eco: {
      backgroundColor: '#b6edaf'
    },
    usa: {
      backgroundColor: '#e3e3e3'
    }
  };

  return (
    <Box
      data-testid="badge-container"
      sx={{
        ...baseStyles,
        ...(compact && compactStyles),
        ...(!compact && variantStyles[variant])
      }}
      title={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {children}
    </Box>
  );
};

const BadgeText = styled(Typography)({
  fontSize: 12,
  fontWeight: 700,
  lineHeight: 1,
  margin: 0,
  padding: 0
});

export default function ProductBadge({
  compact = false,
  icon: Icon,
  label,
  onMouseEnter,
  onMouseLeave,
  title,
  variant = 'default'
}) {
  return (
    <BadgeContainer
      compact={compact}
      variant={variant}
      title={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Icon
        width={compact ? '1.75rem' : '1rem'}
        height={compact ? '1.75rem' : '1rem'}
      />
      {!compact && <BadgeText>{label}</BadgeText>}
    </BadgeContainer>
  );
}

ProductBadge.propTypes = {
  compact: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['eco', 'usa'])
};
