import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import {Box} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

const ProductPromoBadge = ({compact = false, label}) => {
  return (
    <Box
      data-testid="product-promo-badge"
      sx={{
        alignItems: 'center',
        backgroundColor: designTokens.color.red.default.value,
        borderRadius: compact ? 'unset' : '0.5rem 0 0.5rem 0',
        color: 'white',
        display: 'flex',
        fontSize: compact ? '0.75rem' : '0.8125rem',
        fontWeight: 700,
        gap: '0.625rem',
        justifyContent: 'center',
        lineHeight: 1,
        minHeight: compact ? '1.25rem' : '1.5rem',
        padding: compact ? 'unset' : '0.25rem 0.5rem',
        position: compact ? 'relative' : 'absolute',
        whiteSpace: 'nowrap'
      }}>
      {label}
    </Box>
  );
};

ProductPromoBadge.propTypes = {
  compact: PropTypes.bool,
  label: PropTypes.string.isRequired
};

export default function ProductPromoBadges({badges, compact = false}) {
  // Early return if no badges
  if (!badges) return null;

  // Get promo badges (type: 'promo')
  const promoBadges = useMemo(() => {
    return badges.filter((badge) => badge.type === 'promo');
  }, [badges]);

  // If no promo badges, return null
  if (!promoBadges.length) return null;

  // Display the first promo badge
  // Note: If we need to display multiple badges, this would need to be modified
  const badge = promoBadges[0];

  return <ProductPromoBadge compact={compact} label={badge.label} />;
}

ProductPromoBadges.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      params: PropTypes.object,
      type: PropTypes.string.isRequired
    })
  ),
  compact: PropTypes.bool
};
